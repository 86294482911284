import { Controller } from "stimulus";
import $ from 'jquery';

export default class extends Controller {
  static targets = [ "notification" ]

  reloadNotification(event) {
  	let _this = this;
  	let _target = event.currentTarget
  	let id = _target.dataset.id;

    fetch("/en/notifications/" + id)
    .then(data => {return data.text();})
    .then(html => {
      if (html.length > 0) {
      	_this.notificationTarget.outerHTML = html;
      }
    });
  }

  getNotifications(event) {
    let pg = 1;
    pg = event.currentTarget.dataset.pg;

    if ($("#pagination_button").hasClass("loading") == false) {
      $("#pagination_button").addClass("loading");
      $.get( "/en/notifications", {
        pg: pg,
        pagination: true,
      })
      .done(function( data ) {
        console.log(data);
        if (data.length > 1) {
          $("#read-notifications").append(data);
          $("#pagination_button").attr("data-pg", parseInt(pg) + 1);
        } else {
          $("#pagination_button").remove();
        }
        $("#pagination_button.loading").removeClass("loading");
      });
      
    }
  }

  markAllRead(event) {
    $(".notification-card .mark_read").each(function(index) {
      $(this)[0].click();
    });
  }
  
}