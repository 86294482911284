import { Controller } from "stimulus";
import { MDCDialog } from '@material/dialog';
import Rails from "@rails/ujs";

export default class extends Controller {
	static targets = [ "openDialogButton", "dialog"]

	initialize() { 
		this.dialogTargets.forEach((dialogEl) => {
      let dialog = null;

      if (dialogEl.MDCDialog === undefined) {
        dialog = new MDCDialog(dialogEl);
        let submitButton = dialogEl.querySelector("#dialog-submit-button");
                
        if (submitButton != null) {
          submitButton.addEventListener("click", function(event) {
            let form = dialogEl.querySelector('.mdc-dialog__content form.dialog-submit-form');
            if (form.reportValidity())

              if (form.dataset.remote && form.dataset.remote !== false) { 
                Rails.fire(form, 'submit');

                form.addEventListener("ajax:success", function (event) { 
                  const [_data, _status, xhr] = event.detail;

                  let response_html = xhr.responseText;

                  if (form.dataset.replaceId != null)
                    document.getElementById(form.dataset.replaceId).innerHTML = response_html;

                  dialog.close();
                });
              } else { 
                form.submit() 
              }

          });
        }
        
        openDialog(dialog);
      }
    });

    this.openDialogButtonTargets.forEach((btn) => {
      btn.addEventListener("ajax:success", function(event) {
        const [_data, _status, xhr] = event.detail;
        let dialog_html = xhr.responseText;
        const div = document.createElement('div');
        div.innerHTML = dialog_html;
        const dialogEl = div.firstElementChild;
        document.body.appendChild(dialogEl);
      });

      btn.addEventListener("ajax:error", function(xhr, status, error) {
        console.log("Error: ", error);
      }); 

    });

    function openDialog(dialog) {
      if (dialog.root.querySelectorAll(".dialog-submit-form").length > 0) {
        let form_changed = false;
        dialog.listen('click', function(event) {

          if (form_changed == true && event.target != undefined && (event.target.classList.contains("mdc-dialog__scrim") || event.target.classList.contains("mdc-dialog__close"))) {
              
            let confirmationDialogTemplate = document.querySelector("#close-confirmation-dialog-template");
            let confirmationDialogEl = null;
            let confirmationDialog = null;

            const div = document.createElement('div');
            div.innerHTML = confirmationDialogTemplate.innerHTML;

            confirmationDialogEl = div.firstElementChild;
            document.body.appendChild(confirmationDialogEl);

            confirmationDialog = new MDCDialog(confirmationDialogEl);

            confirmationDialog.root.querySelector("#confirmation-save-btn").addEventListener("click", function () {
              confirmationDialog.close();
              let submitButton = dialog.root.querySelector("#dialog-submit-button");
              if (submitButton != undefined)
                submitButton.click();
            });

            confirmationDialog.root.querySelector("#confirmation-close-btn").addEventListener("click", function () {
              confirmationDialog.close();
              dialog.close();
            });

            confirmationDialog.open();
          }
        });

        dialog.root.querySelectorAll("form").forEach(function (form) {
          form.addEventListener("change", function () {
            form_changed = true;
            //prevent closing
            dialog.escapeKeyAction= "";
            dialog.scrimClickAction= "";
            dialog.root.querySelectorAll(".mdc-dialog__close").forEach(function (btn) {
              btn.dataset.mdcDialogAction = '';
            });
          });
        });

      }

      dialog.listen('MDCDialog:opened', () => {
        window.mdcAutoInit();
        
        let autofocusInputs = dialog.root.querySelectorAll("textarea[autofocus='autofocus'], input[autofocus='autofocus']");
        if (autofocusInputs.length > 0) {
          autofocusInputs[0].focus();
        }

      });

      dialog.listen('MDCDialog:closing', function() {
      });

      dialog.listen('MDCDialog:closed', function() {
        dialog.root.remove();
      });

      dialog.open();
    }

  }

}