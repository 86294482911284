// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



import Rails from "@rails/ujs";

import $ from 'jquery'

import * as ActiveStorage from "@rails/activestorage"

//import "channels"

import "../styles/media"

require("controllers")
require("packs/material")

Rails.start()
ActiveStorage.start()

//import styles from '../styles/media'

document.addEventListener("DOMContentLoaded", function(){

  const smoothButtons = document.querySelectorAll(".smooth-button");

  for (const btn of smoothButtons) {
    btn.addEventListener("click", clickSmoothButton);
  }

});

function clickSmoothButton(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop - 16;

  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}