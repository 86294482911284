import mdcAutoInit from '@material/auto-init';

import {MDCRipple} from '@material/ripple';
mdcAutoInit.register('MDCRipple', MDCRipple);

import {MDCTextField} from '@material/textfield';
mdcAutoInit.register('MDCTextField', MDCTextField);

import {MDCTabBar} from '@material/tab-bar';
mdcAutoInit.register('MDCTabBar', MDCTabBar);

import {MDCList} from '@material/list';
mdcAutoInit.register('MDCList', MDCList);

import {MDCMenu} from '@material/menu';
mdcAutoInit.register('MDCMenu', MDCMenu);

import {MDCDrawer} from '@material/drawer';
mdcAutoInit.register('MDCDrawer', MDCDrawer);

import {MDCSelect} from '@material/select';
mdcAutoInit.register('MDCSelect', MDCSelect);

import {MDCSwitch} from '@material/switch';
mdcAutoInit.register('MDCSwitch', MDCSwitch);

import {MDCCheckbox} from '@material/checkbox';
mdcAutoInit.register('MDCCheckbox', MDCCheckbox);

import {MDCRadio} from '@material/radio';
mdcAutoInit.register('MDCRadio', MDCRadio);

import {MDCFormField} from '@material/form-field';
mdcAutoInit.register('MDCFormField', MDCFormField);

import {MDCSnackbar} from '@material/snackbar';
mdcAutoInit.register('MDCSnackbar', MDCSnackbar);

import {MDCChipSet} from '@material/chips';
mdcAutoInit.register('MDCChipSet', MDCChipSet);

import {MDCChip} from '@material/chips';
mdcAutoInit.register('MDCChip', MDCChip);

import {MDCTooltip} from '@material/tooltip';
mdcAutoInit.register('MDCTooltip', MDCTooltip);

import {MDCDataTable} from '@material/data-table';
mdcAutoInit.register('MDCDataTable', MDCDataTable);

import {MDCBanner} from '@material/banner';
mdcAutoInit.register('MDCBanner', MDCBanner);

import {MDCDialog} from '@material/dialog';
mdcAutoInit.register('MDCDialog', MDCDialog);

import {MDCSlider} from '@material/slider';
mdcAutoInit.register('MDCSlider', MDCSlider);

import {MDCCircularProgress} from '@material/circular-progress';
mdcAutoInit.register('MDCCircularProgress', MDCCircularProgress);

import {MDCLinearProgress} from '@material/linear-progress';
mdcAutoInit.register('MDCLinearProgress', MDCLinearProgress);

import {MDCSegmentedButton} from '@material/segmented-button';
import {MDCSegmentedButtonSegment} from '@material/segmented-button';
mdcAutoInit.register('MDCSegmentedButton', MDCSegmentedButton);


window.mdcAutoInit = function () {
  mdcAutoInit();
  console.log("MDC Init");
};

document.addEventListener("MDCAutoInit:End", () => {
    
  //SEGMENTED Button
  const segmentedButtons = document.querySelectorAll('.mdc-segmented-button');
  segmentedButtons.forEach((segButtonEl) => {
    let segButton = segButtonEl.MDCSegmentedButton;

    //console.log("segButton", segButton)
    //segButton.selectSegment(1);

    segButtonEl.addEventListener('click', (e) => {
      e.preventDefault();
    })

    let input = segButtonEl.querySelector("input");

    segButton.segments.forEach((seg) => {
      if (seg.root.dataset.value == input.value)
        seg.setSelected();
    });

    segButton.segments.forEach((segment) => {

      segment.listen('selected', () => {
        //console.log("MDCSegmentedButtonSegment", segment.index);
        segment.setSelected();
        
        input.value = segment.root.dataset.value;
        input.dispatchEvent(new Event('change'));

        segButton.segments.forEach((seg) => {
          if (seg.index != segment.index)
            seg.setUnselected();
        });
      });
    });

  });

  //BANNER
  const bannerElements = document.querySelectorAll('.mdc-banner.banner-open');
  bannerElements.forEach((bannerEl) => {
    let banner = bannerEl.MDCBanner;
    if (!banner.isOpen)
      banner.open();
  });
  
  //SELECT
  const selectElements = document.querySelectorAll('.mdc-select');  
  selectElements.forEach((selectEl) => {
    if (selectEl.getAttribute('listener4Input') !== 'true') {
      let select = selectEl.MDCSelect;

      if (selectEl.classList.contains('no-default-selected')) {
        selectEl.querySelectorAll('.mdc-deprecated-list-item--selected').forEach((itemEl, i) => {
          itemEl.classList.remove("mdc-deprecated-list-item--selected");
        });
      } else {
        selectEl.querySelectorAll('.mdc-deprecated-list-item').forEach((itemEl, i) => {
          if (itemEl.classList.contains("selected"))
            select.setSelectedIndex(i);
        });
      }

      let initSelectInput = function(e) {
        if (e.target.querySelector('input') !== null)
          e.target.querySelector('input').value = select.value;
      }

      select.listen('MDCSelect:change', initSelectInput);
      console.log("listener4Input", "ADDED");
      selectEl.setAttribute('listener4Input', 'true');
    }

  });

  //CHECKBOX
  const checkboxElements = document.querySelectorAll('.mdc-checkbox');  
  checkboxElements.forEach((checkboxEl) => {
    let checkbox = checkboxEl.MDCCheckbox;

    if (checkbox != undefined) {

      checkbox.listen('click', () => {
        checkboxEl.classList.remove('red');
        checkboxEl.parentElement.querySelector('label').classList.remove('red');
      });

    }

  });


  //PROGRESS
  const linearProgressElements = document.querySelectorAll('.mdc-linear-progress.predefined-value');  
  linearProgressElements.forEach((progressEl) => {
    let progress = progressEl.MDCLinearProgress;
    progress.progress = progressEl.dataset.value;
  });

  const circularProgressElements = document.querySelectorAll('.mdc-circular-progress.predefined-value');  
  circularProgressElements.forEach((progressEl) => {
    let progress = progressEl.MDCCircularProgress;
    progress.progress = progressEl.dataset.value;
  });

});

window.addEventListener('DOMContentLoaded', (event) => {
  //NOTE: => javascript_pack_tag 'admin'  schould be WITHOUT  'async: true' to be able to fire DOMContentLoaded from JS
  window.mdcAutoInit();
});