import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["fileField", "imagePreview", "uploadButton"]

  initialize () {
    let _this = this;
    let fileField = _this.fileFieldTarget;
    let imagePreview = _this.imagePreviewTarget;
    let fileUploadButton = _this.uploadButtonTarget; 

    if (fileUploadButton != null) {
      fileUploadButton.addEventListener('click', function(event) {
        fileField.click();
      });
    }

    fileField.onchange = evt => {
      let fileUrl;

      fileUrl = URL.createObjectURL(evt.target.files[0]);

      if (fileUrl != null && fileUrl != undefined) {
        if (imagePreview.dataset.type == "image") {
          imagePreview.src = fileUrl;
          imagePreview.onload = function() {
            URL.revokeObjectURL(imagePreview.src) // free memory
          }

        } else if (imagePreview.dataset.type == "background") {
          imagePreview.style.backgroundImage = "url('" + fileUrl + "')";
          imagePreview.innerHTML = "";
        }

      }

    }

  }

}
